import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from '@am92/web-http'
import { HANDSHAKE_ENABLED_HTTP, asHttp } from '~/src/Configurations/WebHttp'

const performHandshake = async () => {
  const promises = HANDSHAKE_ENABLED_HTTP.map(handshake)
  const responses = await Promise.allSettled(promises)
  responses.forEach(response => {
    if (response.status === 'rejected') {
      throw response.reason
    }
  })
}

const handshake = async () => {
  if (!asHttp) {
    return
  }

  const options: WebHttpRequestOptions = {
    url: '/handshake',
    method: 'GET',
    webHttpConfig: {
      disableCrypto: true
    }
  }

  try {
    const response = await asHttp.request(options)
    const { data: body } = response
    const { data = {} } = body
    const { publicKey = '' } = data
    asHttp.context.set(WEB_HTTP_CONTEXT.PUBLIC_KEY, publicKey)
  } catch (err) {
    throw err
  }
}

export default performHandshake
