import React from 'react'
import { RouteObject, createBrowserRouter } from 'react-router-dom'
// custom components
import MainLayout from '~/src/Layouts/Main.Layout'
// constants
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import ValidationLayout from '../Layouts/Validation.Layout'
import AppStore from './AppStore'
import { ErrorBoundary } from '../Components/ErrorBoundary/ErrorBoundary'
import PasswordExpiredResetPasswordPage from '../Pages/PasswordExpiredResetPassword/PasswordExpiredResetPassword.Page'
import { SELF_DOMAIN } from './env'

const ForgotPasswordSetPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordSetPassword" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ForgotPasswordSetPassword/ForgotPasswordSetPassword.Page'
    ) as any
)
const ChangePasswordSetPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePasswordSetPassword" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ChangePasswordSetPassword/ChangePasswordSetPassword.Page'
    ) as any
)
const ChangePasswordVerifyPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePasswordVerifyPassword" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ChangePasswordVerifyPassword/ChangePasswordVerifyPassword.Page'
    ) as any
)

const CreateUsernameIdentifierPanEmailMobile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreateUsernameIdentifierPanEmailMobile" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/CreateUsernameIdentifierPanEmailMobile/CreateUsernameIdentifierPanEmailMobile.Page'
    ) as any
)

const CreateUsernameSetUsername = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreateUsernameSetUsername" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/CreateUsernameSetUsername/CreateUsernameSetUsername.Page'
    ) as any
)

const SubsequentLoginChallengePassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SubsequentLoginChallengePassword" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/SubsequentLoginChallengePassword/SubsequentLoginChallengePassword.page'
    ) as any
)

const LoginIdentifierEmailMobile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginIdentifierEmailMobile" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/LoginIdentifierEmailMobile/LoginIdentifierEmailMobile.Page'
    ) as any
)
const LoginChallengePassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginChallengePassword" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/LoginChallengePassword/LoginChallengePassword.Page'
    ) as any
)

const ForgotPasswordIdentifierPanEmailMobile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordIdentifierPanEmailMobile" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ForgotPasswordIdentifierPanEmailMobile/ForgotPasswordIdentifierPanEmailMobile.Page'
    ) as any
)

const ForgotUsernameIdentifierPanEmailMobile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotUsernameIdentifierPanEmailMobile" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ForgotUsernameIdentifierPanEmailMobile/ForgotUsernameIdentifierPanEmailMobile.Page'
    ) as any
)

const ForgetUsernameSetUsername = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgetUsernameSetUsername" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ForgotUsernameSetUsername/ForgotUsernameSetUsername.Page'
    ) as any
)

const UnlockAccountActionSelection = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UnlockAccountActionSelection" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/UnlockAccountActionSelection/UnlockAccountActionSelection.Page'
    ) as any
)

const UnlockAccountIdentifierPanEmailMobile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UnlockAccountIdentifierPanEmailMobile" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/UnlockAccountIdentifierPanEmailMobile/UnlockAccountIdentifierPanEmailMobile.Page'
    ) as any
)

const LoginIdentifierUsername = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginIdentifierUsername" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/LoginIdentifierUsername/LoginIdentifierUsername.Page'
    ) as any
)

const LoginIdentifierQrcode = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginIdentifierQrcode" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/LoginIdentifierQrcode/LoginIdentifierQrcode.Page'
    ) as any
)

const AccessCode = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AccessCode" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/AccessCodeForm/AccessCodeForm.Page'
    ) as any
)
const ForgotUserNameOtpCodeForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotUserNameOtpCodeForm" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ForgotUserNameOtpCodeForm/ForgotUserNameOtpCodeForm.Page'
    ) as any
)
const UnlockAccountOtpCodeForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UnlockAccountOtpCodeForm" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/UnlockAccountOtpCodeForm/UnlockAccountOtpCodeForm.Page'
    ) as any
)

const ForgotPasswordOtpCodeForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordOtpCodeForm" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ForgotPasswordOtpCodeForm/ForgotPasswordOtpCodeForm.Page'
    ) as any
)

const UnlockAccountSetPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UnlockAccountSetPassword" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/UnlockAccountSetPassword/UnlockAccountSetPassword.Page'
    ) as any
)

const ValidateSSO = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ValidateSSO" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ValidateSSO/ValidateSSO.Page'
    ) as any
)

//SSO errors screens
const SomethingWentWrongError = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SomethingWentWrongError" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/SomethingWentWrongError/SomethingWentWrongError.Page'
    ) as any
)
const SessionExpired = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SessionExpired" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/SessionExpired/SessionExpired.Page'
    ) as any
)
const CreateUsernamOTP = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SomethingWentWrongError" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/CreateUsernameOtpCodeForm/CreateUsernameOtpCodeForm.Page'
    ) as any
)

const NotFoundPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotFound" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/NotFound/NotFound.Page'
    ) as any
)

const NRiAccountSelection = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotFound" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/NRiAccountSelection/NRiAccountSelection.Page'
    ) as any
)

const isJourneyValidated = () => {
  const { isValidated } = AppStore.getState().journey
  const { sessionId = '' } = AppStore.getState().journey.ssoValidationResponse
  if (!sessionId) {
    const redirectUrl = `${SELF_DOMAIN}${APP_ROUTES.SESSION_EXPIRED.pathname}`
    window.location.href = redirectUrl
  }
  if (!isValidated) {
    throw new Response('Not Found', { status: 404 })
  }
  return {}
}

const routeObj: RouteObject[] = [
  {
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      // Login Flow
      {
        path: APP_ROUTES.LOGIN_IDENTIFIER_EMAIL_MOBILE.pathname,
        element: <LoginIdentifierEmailMobile />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname,
        element: <LoginIdentifierUsername />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.LOGIN_IDENTIFIER_QRCODE.pathname,
        element: <LoginIdentifierQrcode />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.SUBSEQUENT_LOGIN_CHALLENGE_PASSWORD.pathname,
        element: <SubsequentLoginChallengePassword />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.LOGIN_CHALLENGE_PASSWORD.pathname,
        element: <LoginChallengePassword />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.LOGIN_SELECT_NRI_ACCOUNT.pathname,
        element: <NRiAccountSelection />,
        loader: isJourneyValidated
      },

      // Forgot Password Flow
      {
        path: APP_ROUTES.FORGOT_PASSWORD_IDENTIFIER_PAN_EMAIL_MOBILE.pathname,
        element: <ForgotPasswordIdentifierPanEmailMobile />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.FORGOT_PASSWORD_SET_PASSWORD.pathname,
        element: <ForgotPasswordSetPassword />,
        loader: isJourneyValidated
      },

      // Forgot Username Flow
      {
        path: APP_ROUTES.FORGOT_USERNAME_IDENTIFIER_PAN_EMAIL_MOBILE.pathname,
        element: <ForgotUsernameIdentifierPanEmailMobile />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.FORGOT_USERNAME_SET_USERNAME.pathname,
        element: <ForgetUsernameSetUsername />,
        loader: isJourneyValidated
      },
      // Change Password
      {
        path: APP_ROUTES.CHANGE_PASSWORD_SET_PASSWORD.pathname,
        element: <ChangePasswordSetPassword />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.CHANGE_PASSWORD_CHALLENGE_PASSWORD.pathname,
        element: <ChangePasswordVerifyPassword />,
        loader: isJourneyValidated
      },

      // Unlock Account
      {
        path: APP_ROUTES.UNLOCK_ACCOUNT_ACTION_SELECTION.pathname,
        element: <UnlockAccountActionSelection />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.UNLOCK_ACCOUNT_IDENTIFIER_PAN_EMAIL_MOBILE.pathname,
        element: <UnlockAccountIdentifierPanEmailMobile />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.LOGIN_CHALLENGE_ACCESS_CODE.pathname,
        element: <AccessCode />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.FORGOT_USERNAME_CHALLENGE_OTP.pathname,
        element: <ForgotUserNameOtpCodeForm />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.UNLOCK_ACCOUNT_CHALLENGE_OTP.pathname,
        element: <UnlockAccountOtpCodeForm />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.FORGOT_PASSWORD_CHALLENGE_OTP.pathname,
        element: <ForgotPasswordOtpCodeForm />,
        loader: isJourneyValidated
      },
      // FORGOT_USERNAME_CHALLENGE_OTP
      {
        path: APP_ROUTES.UNLOCK_ACCOUNT_SET_PASSWORD.pathname,
        element: <UnlockAccountSetPassword />,
        loader: isJourneyValidated
      },

      // Create Username Flow
      {
        path: APP_ROUTES.CREATE_USERNAME_IDENTIFIER_PAN_EMAIL_MOBILE.pathname,
        element: <CreateUsernameIdentifierPanEmailMobile />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.CREATE_USERNAME_SET_USERNAME.pathname,
        element: <CreateUsernameSetUsername />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.CREATE_USERNAME_CHALLENGE_OTP.pathname,
        element: <CreateUsernamOTP />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.PASSWORD_EXPIRED_RESET_PASSWORD.pathname,
        element: <PasswordExpiredResetPasswordPage />,
        loader: isJourneyValidated
      }
      // CreateUsernamOTP
    ]
  } as RouteObject,
  {
    element: <ValidationLayout />,
    children: [
      {
        path: APP_ROUTES.VALIDATE_SSO.pathname,
        element: <ValidateSSO />
      },
      {
        path: APP_ROUTES.SESSION_EXPIRED.pathname,
        element: <SessionExpired />
      },
      {
        path: APP_ROUTES.SOMETHING_WENT_WRONG.pathname,
        element: <SomethingWentWrongError />
      },
      {
        path: APP_ROUTES.ANY.pathname,
        element: <NotFoundPage />
      } as RouteObject
    ]
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
