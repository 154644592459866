import APP_ROUTES from '~/src/Constants/APP_ROUTES'

export const GRATIFICATION_CASES = {
  FORGOT_PASSWORD_SUCCESS: {
    heading: 'Great! You have successfully created a new password.'
  },
  EXPIRED_PASSWORD_SUCCESS: {
    heading: 'Great! You have successfully created a new password.'
  },
  CHANGE_PASSWORD_SUCCESS: {
    heading: 'Great! You have successfully changed your password.'
  },
  UNLOCK_ACCOUNT_ACTION_NO_SUCCESS: {
    heading: 'Great! You have successfully unlocked your account.',
    subHeading:
      'Please do not close this window while we redirect you to the login page.'
  },
  UNLOCK_ACCOUNT_ACTION_YES_SUCCESS: {
    heading: 'Great! You have successfully reset your password.',
    subHeading:
      'Please do not close this window while we redirect you to the login page.'
  },
  UNLOCK_ACCOUNT_ACTION_NO_SUCCESS_CHANGE_PASSWORD_FLOW: {
    heading: 'Great! You have successfully unlocked your account.',
    subHeading:
      'Please do not close this window while we redirect you to the password verfication page.'
  },
  UNLOCK_ACCOUNT_ACTION_YES_SUCCESS_CHANGE_PASSWORD_FLOW: {
    heading: 'Great! You have successfully reset your password.'
  },
  CREATE_USERNAME_SUCCESS: {
    // case where user does not have a username
    heading: 'Great! You have successfully created a new username.',
    subHeading:
      'You can now login with the new username to begin your trading and investing journey.',
    onCloseAction: {
      actionType: 'NAVIGATION',
      pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname
    }
  },
  FORGOT_USERNAME_SUCCESS: {
    // case where user does have a username but have forgotten
    heading: 'Great! Your OTP verification was successful',
    isButtonGroupNeeded: true,
    primaryButtonLabel: 'Login',
    secondaryButtonLabel: 'resend username',
    primaryButtonClickAction: {
      actionType: 'NAVIGATION',
      pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname
    },
    onCloseAction: {
      actionType: 'NAVIGATION',
      pathname: APP_ROUTES.LOGIN_IDENTIFIER_USERNAME.pathname
    }
  },
  CUSTOMER_SUPPORT_SUCCESS: {
    heading: 'Request submitted successfully!',
    subHeading:
      'We have received your service request. Our executive will get in touch with you shortly.'
  }
}
