export const AXIS_DIRECT_URL =
  'https://simplehai.axisdirect.in/app/index.php/user/auth/LoginSupport'

export const OLD_USERNAME_FLOW =
  'https://simplehai.axisdirect.in/app/index.php/user/auth/activateUser'

export const TERMS_CONDITIONS =
  'https://simplehai.axisdirect.in/terms-and-conditions-home'

export const OPEN_ACCOUNT = 'https://digitalaccount.axisdirect.in/register'
