import { DsStack, DsTypography } from '@am92/react-design-system'
import React from 'react'
import ProfileCard from './ProfileCard'

export type Account = {
  settlementType: string
  subAccountId: string
  isPrimary: boolean
  name: string
  accountType: string
}

interface NreProps {
  accounts: Account[]
  selectedAccount: Account
  handleOnSelectAccount: (item: string) => Promise<any>
}

interface NreState {}

class Nre extends React.Component<NreProps, NreState> {
  handleGetCardType = (accountType: string) => {
    const { accounts, selectedAccount } = this.props

    const accountPresent = accounts.find(
      (account: { settlementType: string }) =>
        account.settlementType === accountType
    )

    if (accountPresent) {
      if (selectedAccount.settlementType === accountType) {
        return 'active'
      }
      return 'primary'
    }
    if (!accountPresent) {
      return 'disabled'
    }
    return 'disabled'
  }

  render() {
    const { accounts, handleOnSelectAccount } = this.props

    return (
      <DsStack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--ds-spacing-bitterCold)',
          alignItems: 'center'
        }}
      >
        <DsTypography variant='bodyBoldMedium'>
          NRE - Non-Resident External Account
        </DsTypography>
        <DsStack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <ProfileCard
            onClick={() => handleOnSelectAccount('NRE-PIS')}
            type={this.handleGetCardType('NRE-PIS')}
            heading='NRE PIS'
            description='For investing in Equity'
          />
          <ProfileCard
            onClick={() => handleOnSelectAccount('NRE-NONPIS')}
            type={this.handleGetCardType('NRE-NONPIS')}
            heading='NRE NON'
            description='For investing in IPO, ETF, MFs, Bonds, ESOPs'
          />
        </DsStack>
      </DsStack>
    )
  }
}

export default Nre
