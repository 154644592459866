import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { DsTextField } from '../DsTextField';
import { DsButton } from '../DsButton';
import { DsTextFieldPasswordDefaultProps, DsTextFieldPasswordDefaultState } from './DsTextFieldPassword.Types';
export class DsTextFieldPassword extends Component {
    static defaultProps = DsTextFieldPasswordDefaultProps;
    state;
    constructor(props) {
        super(props);
        this.state = {
            ...DsTextFieldPasswordDefaultState,
            isVisible: props.isVisible
        };
    }
    handleTogglePassword = () => this.setState({ isVisible: !this.state.isVisible });
    render() {
        const { isVisible } = this.state;
        const { type, isVisible: isVisibleProp, endAdornment, disabled, ...restProps } = this.props;
        const inputType = (isVisible && type) || 'password';
        const rightIcon = (_jsx(DsButton, { onClick: this.handleTogglePassword, variant: "text", color: "secondary", disabled: disabled, sx: {
                minWidth: '50px'
            }, children: isVisible ? 'HIDE' : 'SHOW' }));
        return (_jsx(DsTextField, { ...restProps, disabled: disabled, type: inputType, endAdornment: rightIcon }));
    }
}
