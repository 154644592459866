import { DsStack, DsTypography } from '@am92/react-design-system'
import React from 'react'

interface NriHeaderProps {
  name: string
}

interface NriHeaderState {}

class NriHeader extends React.Component<NriHeaderProps, NriHeaderState> {
  render() {
    const { name } = this.props
    return (
      <DsStack sx={{ gap: 'var(--ds-spacing-glacial)' }}>
        <DsStack>
          <DsTypography variant='headingBoldSmall' sx={{ color: '#97144D' }}>
            Hi {name},
          </DsTypography>
        </DsStack>
        <DsStack>
          <DsTypography variant='bodyRegularMedium'>
            Please choose an account to log-in
          </DsTypography>
          <DsTypography
            variant='bodyRegularMedium'
            sx={{ color: 'var(--ds-colour-typoSecondary)' }}
          >
            You can change accounts from your profile page later as well.
          </DsTypography>
        </DsStack>
      </DsStack>
    )
  }
}

export default NriHeader
