import * as React from 'react'
import { DsHeader, DsImage } from '@am92/react-design-system'

// constants
import { HEADER_ASSEST } from '~/src/Constants/ASSEST_MAP'

export interface IWebHeaderProps {}

export default class WebHeader extends React.Component<IWebHeaderProps> {
  render() {
    return (
      <DsHeader
        logo={
          <DsImage
            srcSet={HEADER_ASSEST}
            style={{
              width: '100%'
            }}
          />
        }
        sx={{
          position: 'fixed',
          top: 0,
          zIndex: 2,
          display: { md: 'block', xs: 'none' },
          height: 'var(--ds-rules-headerMobileHeight)',
          boxShadow: 'none'
        }}
      />
    )
  }
}
