import * as React from 'react'

import { DsBox, DsStack } from '@am92/react-design-system'
import { Outlet } from 'react-router-dom'
import WebHeader from '../Components/WebHeader'
import WebLeftContent from '../Components/WebLeftContent'
import WebFooter from '../Components/WebFooter'
import { PLATFORM_DOMAIN } from '../Configurations/env'
import APP_ROUTES from '../Constants/APP_ROUTES'
import withErrorConnect from '../Lib/withErrorConnect'
import { isValidated } from '../Redux/Journey/Selectors'
import ssoInitiateAction from '../Redux/Journey/Services/SSO/initiateSSO.Service'
import { WithRouter } from '../Lib/withRouter'

export interface Actions {
  initiateSSO: any
}
export interface IMainLayoutProps extends WithRouter {
  isValidatedSelect: boolean
  actions: Actions
}
export class MainLayout extends React.Component<IMainLayoutProps> {
  state = {
    pageHeight: `${window.innerHeight}px`,
    showPopup: false
  }

  async componentDidMount(): Promise<void> {
    window.addEventListener('resize', this.handleDrawerOnResize)

    // const { isValidatedSelect } = this.props

    // if (!isValidatedSelect) {
    //   this.handleInitiate()
    // }
  }

  // handleInitiate = async () => {
  //   const { navigateTo, actions } = this.props

  //   const requestPayload = {
  //     type: 'LOGIN',
  //     redirectURL: PLATFORM_DOMAIN
  //   }

  //   const LoginInitiateSSOResponse = await actions.initiateSSO(requestPayload)
  //   if (LoginInitiateSSOResponse._isCustomError) {
  //     this.navigateToSomethingWentWrong()
  //     console.log('failed API - loginInitiateSSO')
  //     return
  //   }
  //   const { redirectURL } = LoginInitiateSSOResponse

  //   const redURL = new URL(redirectURL)
  //   const url = `${APP_ROUTES.VALIDATE_SSO.pathname}${redURL.search}`

  //   navigateTo(url)
  // }

  navigateToSomethingWentWrong = () => {
    const { navigateTo } = this.props
    navigateTo(APP_ROUTES.SOMETHING_WENT_WRONG.pathname)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDrawerOnResize)
  }

  handleDrawerOnResize = () => {
    this.setState({ pageHeight: `${window.innerHeight}px` })
  }

  render() {
    const { pageHeight } = this.state
    const contentMobileHeight = `calc(${pageHeight})`
    // const contentDesktopHeight = `calc(${pageHeight} - var(--ds-rules-headerDesktopHeight))`

    return (
      <DsBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {/* // Web Header Component - only web */}
        <WebHeader />

        <DsBox
          sx={{
            width: { md: '58%', xs: '100%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: {
              md: 'var(--ds-spacing-whiteHot)',
              xs: 'var(--ds-spacing-zero)'
            }
          }}
        >
          {/* // Main Component // Left Side Component // Right Side Component // Web */}
          <DsBox
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              overflow: 'hidden',
              border: {
                md: '1px solid var(--ds-colour-strokeDefault)',
                xs: 'none'
              },
              borderRadius: 'var(--ds-spacing-glacial)'
            }}
          >
            <WebLeftContent />
            <DsStack
              sx={{
                pb: 'var(--ds-spacing-bitterCold)',
                pt: {
                  md: 'var(--ds-spacing-molten)',
                  xs: 'var(--ds-spacing-bitterCold)'
                },
                pl: {
                  md: 'var(--ds-spacing-superheated)',
                  xs: 'var(--ds-spacing-bitterCold)'
                },
                pr: {
                  md: 'var(--ds-spacing-superheated)',
                  xs: 'var(--ds-spacing-bitterCold)'
                },
                width: { md: '60%', xs: '100%' },
                height: {
                  xs: contentMobileHeight,
                  md: 'auto'
                },
                borderLeft: '1px solid var(--ds-colour-strokeDefault)'
              }}
            >
              <Outlet />
            </DsStack>
          </DsBox>
          {/* Footer Component */}
          <WebFooter />
        </DsBox>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any) => {
  const isValidatedSelect = isValidated(state)
  return {
    isValidatedSelect
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    initiateSSO: (requestData: any) => dispatch(ssoInitiateAction(requestData))
  }
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(MainLayout)
