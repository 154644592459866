import React, { useEffect } from 'react'
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

export default function withRouter(
  Child: React.ElementType<any>
): React.ElementType<any> {
  return (props: any): React.JSX.Element => {
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = useParams()
    const navigateTo = (route: To | number, options?: NavigateOptions) => {
      if (typeof route === 'number') {
        navigate(route)
      } else {
        navigate(route, options)
      }
    }
    const { pathname = '' } = location
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    return (
      <Child
        {...props}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        navigate={navigate}
        location={location}
        params={params}
        navigateTo={navigateTo}
      />
    )
  }
}

export interface WithRouter {
  setSearchParams: () => void
  navigate: () => void
  navigateTo: (route: To | number, options?: NavigateOptions) => void
  searchParams: any
  location: any
  params: any
}
