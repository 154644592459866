import React from 'react'

import { CHECK_VALIDATE_ASSEST, CHECK_ASSEST } from '~/src/Constants/ASSEST_MAP'
import { IPasswordValidatorProps } from './CredentialPolicy.type'

import { DsBox, DsImage, DsTypography } from '@am92/react-design-system'
class CredentialPolicy extends React.Component<IPasswordValidatorProps> {
  render() {
    return (
      <DsBox>
        <DsTypography
          component={'p'}
          variant="bodyRegularSmall"
          fontWeight={700}
          sx={{
            color: 'var(--ds-colour-typoSecondary)',
            mb: 'var(--ds-spacing-bitterCold)'
          }}
        >
          {this.props.title}
        </DsTypography>
        <DsBox
          sx={{
            display: 'grid',
            gridTemplateColumns: { lg: 'auto auto', md: 'auto auto' },
            rowGap: 'var(--ds-spacing-glacial)'
          }}
        >
          {this.props.validationList?.map((item, index) => (
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--ds-spacing-glacial)'
              }}
              key={index}
            >
              <DsBox>
                <DsImage
                  srcSet={item.regex ? CHECK_VALIDATE_ASSEST : CHECK_ASSEST}
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                />
              </DsBox>
              <DsTypography component={'p'} variant="bodyRegularSmall">
                {item.text}
              </DsTypography>
            </DsBox>
          ))}
        </DsBox>
      </DsBox>
    )
  }
}

export default CredentialPolicy
