import { WebHttpRequestOptions } from '@am92/web-http'
import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { postLoginCheckServiceName, postLoginCheckActions } from '../../Actions'

export type PostLoginCheckData = {
  userId: string
  refreshToken: string
  redirectUrl: string
  subAccountId: string
}

async function postLoginCheck(reqData: PostLoginCheckData) {
  const { userId, refreshToken, redirectUrl, subAccountId } = reqData
  const options: WebHttpRequestOptions = {
    url: '/customers/post-login-check',
    method: 'POST',
    data: { userId, refreshToken, redirectUrl },
    headers: {
      'X-SubAccountID': subAccountId
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  return body
}

const postLoginCheckService = serviceActionCreator<PostLoginCheckData>(
  postLoginCheckActions,
  postLoginCheck
)

export default postLoginCheckService
export { postLoginCheckServiceName }
