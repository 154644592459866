import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export function withBreakpoints(Child) {
    return (props) => {
        const theme = useTheme();
        const { keys } = theme.breakpoints;
        let breakPoints = {};
        keys.forEach((key) => {
            breakPoints[key] = useMediaQuery(theme.breakpoints.only(key));
        });
        return _jsx(Child, { ...props, breakpoints: breakPoints });
    };
}
