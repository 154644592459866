import * as React from 'react'
import { To } from 'react-router'
import CreatePasswordForm from '~/src/Components/CreatePassword/CreatePasswordForm'
import GratificationDialog from '~/src/Components/GratificationDialog/GratificationDialog'
import { GRATIFICATION_CASES } from '~/src/Components/GratificationDialog/GratificationScenarios'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { WithRouter } from '~/src/Lib/withRouter'
import { updateCustomerNameAction } from '~/src/Redux/Journey/Actions'
import {
  getPasswords,
  getRefId,
  getSessionId,
  getSubAccountId,
  getValidateAccessCode
} from '~/src/Redux/Journey/Selectors'
import PasswordExpiredResetPasswordAction, {
  passwordExpiredResetPasswordData
} from '~/src/Redux/Journey/Services/PasswordExpiredResetPassword/passwordExpiredResetPassword.Service'
import postLoginCheckService, {
  PostLoginCheckData
} from '~/src/Redux/Journey/Services/PostLoginCheck/postLoginCheck.Service'
import completeSSOAction, {
  CompleteSSOData
} from '~/src/Redux/Journey/Services/SSO/completeSSO.Service'
import { triggerCleverTapEvent } from '~/src/utils/global'
interface FormValues {
  newPassword: string
  confirmNewPassword: string
}

type ActionTypes = {
  PasswordExpiredResetPassword: (
    requestData: passwordExpiredResetPasswordData
  ) => any
  completeSSO: (param: CompleteSSOData) => Promise<any>
  updateCustomerName: (param: string) => Promise<any>
  postLoginCheck: (param: PostLoginCheckData) => Promise<any>
}

export interface IPasswordExpiredResetPasswordProps
  extends WithRouter,
    FormValues {
  actions: ActionTypes
  handleError: (res: any) => void
  validateAccessCode: any
  refId: string
  sessionId: string
  formRef: React.Ref<any>
  subAccountId: string
}

class PasswordExpiredResetPassword extends React.PureComponent<IPasswordExpiredResetPasswordProps> {
  state = { completePayload: null, showGratification: false }

  navigateTo = (route: To) => () => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleSubmit = async (values: FormValues) => {
    const { actions, handleError, subAccountId } = this.props
    const { newPassword, confirmNewPassword } = values
    const requestData: passwordExpiredResetPasswordData = {
      newPassword,
      confirmNewPassword,
      subAccountId
    }
    const PasswordExpiredResetPasswordResponse =
      await actions.PasswordExpiredResetPassword(requestData)
    if (PasswordExpiredResetPasswordResponse._isCustomError) {
      handleError(PasswordExpiredResetPasswordResponse)
      return
    }
    this.setState({ showGratification: true })
    // setTimeout(() => {
    //   this.handlePostLoginJourney()
    // }, 3000)
  }

  handlePostLoginJourney = async () => {
    const redirectUrl = await this.handleCompleteSSO()
    redirectUrl && this.handlePostLoginCheck(redirectUrl)
  }

  handleCompleteSSO = async () => {
    const {
      actions,
      handleError,
      sessionId,
      validateAccessCode,
      subAccountId
    } = this.props
    const { refreshToken, name } = validateAccessCode

    // update user detail in redux before redirecting
    const customerName = name.split(' ')[0]
    actions.updateCustomerName(customerName)

    const completeSSORequestData: CompleteSSOData = {
      refreshToken: refreshToken.token,
      sessionId,
      metadata: { ...validateAccessCode, subAccountId },
      subAccountId
    }
    const completeSSOResponse = await actions.completeSSO(
      completeSSORequestData
    )
    if (completeSSOResponse._isCustomError) {
      handleError(completeSSOResponse)
      return
    }
    const { redirectURL } = completeSSOResponse.data
    return redirectURL
  }

  handlePostLoginCheck = async (redirectUrl: string) => {
    const { actions, validateAccessCode, subAccountId } = this.props
    const { userId, refreshToken } = validateAccessCode
    const postLoginCheckRequestData: PostLoginCheckData = {
      userId,
      refreshToken: refreshToken.token,
      subAccountId,
      redirectUrl: redirectUrl.split('sso')[0]
    }
    const postLoginCheckResponse = await actions.postLoginCheck(
      postLoginCheckRequestData
    )
    if (postLoginCheckResponse._isCustomError) {
      const { error } = postLoginCheckResponse
      const { code, data } = error
      const { redirectUrl } = data
      const customAttributes = {
        Event_Status: 'Fail',
        Screen_Name: 'PostLogin_Check',
        Error_Code: code
      }
      triggerCleverTapEvent('Login', customAttributes)
      window.location.href = redirectUrl
      return
    }
    const customAttributes = {
      Event_Status: 'Pass',
      Screen_Name: 'PostLogin_Check'
    }
    triggerCleverTapEvent('Login', customAttributes)
    window.location.href = redirectUrl // returning to initiator
    return
  }

  render() {
    const { newPassword, confirmNewPassword, formRef } = this.props
    const initialValues: FormValues = { newPassword, confirmNewPassword }
    const { showGratification } = this.state
    return (
      <>
        <CreatePasswordForm
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          formRef={formRef}
        />
        <GratificationDialog
          {...GRATIFICATION_CASES.EXPIRED_PASSWORD_SUCCESS}
          showGratification={showGratification}
          onCloseAction={{
            actionType: 'FUNCTION',
            onCloseFunction: this.handlePostLoginJourney
          }}
        />
      </>
    )
  }
}
const mapStateToProps = (state: any) => {
  const { newPassword, confirmNewPassword } = getPasswords(state)
  const validateAccessCode = getValidateAccessCode(state)
  const refId = getRefId(state)
  const sessionId = getSessionId(state)
  const subAccountId = getSubAccountId(state)

  return {
    newPassword,
    confirmNewPassword,
    validateAccessCode,
    sessionId,
    refId,
    subAccountId
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    PasswordExpiredResetPassword: (
      requestData: passwordExpiredResetPasswordData
    ) => dispatch(PasswordExpiredResetPasswordAction(requestData)),
    completeSSO: (requestData: CompleteSSOData) =>
      dispatch(completeSSOAction(requestData)),
    updateCustomerName: (name: string) =>
      dispatch(updateCustomerNameAction(name)),
    postLoginCheck: (requestData: PostLoginCheckData) =>
      dispatch(postLoginCheckService(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordExpiredResetPassword)
