import React from 'react'
import {
  DsBox,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { PureComponent } from 'react'
import { SSO_ERROR_ASSEST } from '~/src/Constants/ASSEST_MAP'

class SomethingWentWrongError extends PureComponent {
  render() {
    return (
      <DsStack
        spacing="var(--ds-spacing-frostbite)"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '100%'
        }}
      >
        <DsBox>
          <DsImage srcSet={SSO_ERROR_ASSEST} />
        </DsBox>
        <DsTypography variant="headingBoldLarge">
          Something Went Wrong
        </DsTypography>
        <DsTypography variant="bodyRegularLarge">
          We're trying to figure out this issue. Please close this tab and try
          again.
        </DsTypography>
      </DsStack>
    )
  }
}

export default SomethingWentWrongError
