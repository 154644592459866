import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const loginWithRefreshTokenServiceName = 'auth/loginWithRefreshToken'
export const loginWithRefreshTokenTraceActions = traceActionsCreator(
  loginWithRefreshTokenServiceName
)

export const loginServiceName = 'auth/login'
export const loginTraceActions = traceActionsCreator(
  loginWithRefreshTokenServiceName
)

export const logoutTraceAction = createAction<string, string>(
  'journey/logoutTraceActions'
)

export const logoutTraceActionServiceName = 'auth/logoutTraceAction'
export const logoutTraceActions = traceActionsCreator(
  logoutTraceActionServiceName
)
