import { WebHttpRequestOptions } from '@am92/web-http'
import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { completeSSOServiceName, completeSSOActions } from '../../Actions'
import AppStore from '~/src/Configurations/AppStore'

export type CompleteSSOData = {
  sessionId: string
  refreshToken: string
  metadata?: any
  subAccountId?: string
}

async function completeSSO(reqData: CompleteSSOData) {
  const { sessionId, refreshToken, metadata, subAccountId } = reqData
  const { ssoValidationResponse } = AppStore.getState().journey
  const { metadata: metaDataFromSource } = ssoValidationResponse
  const completePayload = {
    sessionId,
    refreshToken,
    metadata:
      (metaDataFromSource && {
        ...metadata,
        ...metaDataFromSource
      }) ||
      metadata
  }
  const options: WebHttpRequestOptions = {
    url: '/sso/complete',
    method: 'POST',
    data: completePayload,
    headers: {
      'X-SubAccountID': subAccountId
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  return body
}

const completeSSOService = serviceActionCreator<CompleteSSOData>(
  completeSSOActions,
  completeSSO
)

export default completeSSOService
export { completeSSOServiceName }
