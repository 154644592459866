import * as React from 'react'
import { connect } from 'react-redux'
import {
  DsButton,
  DsStack,
  DsTextField,
  DsTextFieldPassword
} from '@am92/react-design-system'
import { Formik, FormikProps } from 'formik'

// custom components
import withRouter, { WithRouter } from '~/src/Lib/withRouter'
import CredentialPolicy from '~/src/Components/CredentialPolicy'
import ContainerLabel from '~/src/Components/ContainerLabel'
import PageHeader from '~/src/Components/PageHeader'

// utils
import { sixTwelveChars, oneDigit, oneAlphabet } from '~/src/utils/Validations'
import createPasswordValidationSchema from './CreatePasswordForm.Schema'
import { getErrorCodeSelector } from '~/src/Redux/Error/Selectors'

interface FormValues {
  newPassword: string
  confirmNewPassword: string
}

export interface ICreatePasswordProps extends WithRouter, FormValues {
  initialValues: FormValues
  onSubmit: (values: FormValues) => Promise<any>
  errorCode: any
  formRef: React.Ref<any>
}

class CreatePasswordForm extends React.Component<ICreatePasswordProps> {
  renderForm = (formikBag: FormikProps<FormValues>) => {
    const {
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      errors,
      values,
      isValid,
      isSubmitting,
      setFieldError
    } = formikBag
    const { errorCode } = this.props
    // errorCode &&
    // setFieldError(
    //   'newPassword',
    //   'Please do not use the previously used password'
    // )
    const newPasswordError = !!(
      touched.newPassword &&
      errors.newPassword &&
      values.newPassword
    )

    const newPasswordHelperText =
      (values.newPassword && touched.newPassword && errors.newPassword) || ''

    // const confirmPasswordError = !!(
    //   touched.confirmNewPassword &&
    //   errors.confirmNewPassword &&
    //   values.confirmNewPassword
    // )

    // const confirmPasswordHelperText =
    //   (values.confirmNewPassword &&
    //     touched.confirmNewPassword &&
    //     errors.confirmNewPassword) ||
    //   ''

    const confirmPasswordError = errors.confirmNewPassword ? true : false
    const confirmPasswordHelperText = errors.confirmNewPassword
    // const yearlyIncomeValue = values.yearlyIncome

    const validationPolicy = [
      {
        text: 'Has 6-12 characters?',
        regex: sixTwelveChars(values.newPassword)
      },
      {
        text: 'Has one digit?',
        regex: oneDigit(values.newPassword)
      },
      {
        text: 'Has one letter?',
        regex: oneAlphabet(values.newPassword)
      }
    ]

    return (
      <form style={{ height: '100%' }} onSubmit={handleSubmit}>
        <DsStack
          sx={{
            height: '100%',
            justifyContent: { md: 'unset', xs: 'space-between' }
          }}
        >
          <DsStack>
            <DsTextFieldPassword
              fullWidth
              name="newPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              label="New Password"
              placeholder="Enter new password"
              error={newPasswordError}
              helperText={newPasswordHelperText}
              sx={{ mb: 'var(--ds-spacing-mild)' }}
            />
            <CredentialPolicy
              title="Should not be same as username or previous password. Preferably one special character (@ # $ ! _ % & *  -)"
              validationList={validationPolicy}
            />
            <DsTextField
              fullWidth
              type="password"
              name="confirmNewPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmNewPassword}
              label="Confirm Password"
              placeholder="Re-enter new password"
              error={confirmPasswordError}
              helperText={confirmPasswordHelperText}
              sx={{ mt: 'var(--ds-spacing-mild)' }}
            />
          </DsStack>

          <DsStack>
            <DsButton
              type="submit"
              size="large"
              disabled={!isValid && !isSubmitting}
              sx={{
                mt: 'var(--ds-spacing-warm)'
              }}
            >
              Create Password
            </DsButton>
          </DsStack>
        </DsStack>
      </form>
    )
  }

  render() {
    const { initialValues, formRef } = this.props
    return (
      <DsStack sx={{ position: 'relative', flexGrow: 1 }}>
        <PageHeader showAxisLogo={false} />
        <DsStack
          spacing="var(--ds-spacing-warm)"
          sx={{
            flexGrow: 1
          }}
        >
          <ContainerLabel
            label="Create new password"
            subLabel="Fulfill the password policy to create a new password"
          />
          <DsStack sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
            <Formik
              initialValues={initialValues}
              onSubmit={this.props.onSubmit}
              validateOnMount
              enableReinitialize
              validationSchema={createPasswordValidationSchema}
              innerRef={formRef}
            >
              {this.renderForm}
            </Formik>
          </DsStack>
        </DsStack>
      </DsStack>
    )
  }
}

// export default CreatePasswordForm

const mapStateToProps = (state: any) => {
  const errorCode = getErrorCodeSelector(state)
  return {
    errorCode
  }
}
export default withRouter(connect(mapStateToProps)(CreatePasswordForm))
