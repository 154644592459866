import * as React from 'react'
import { DsBox, DsImage, DsLink, DsTypography } from '@am92/react-design-system'

// constants
import {
  APPLE_STORE_ASSEST,
  PLAY_STORE_ASSEST
} from '~/src/Constants/ASSEST_MAP'

export interface IWebFooterProps {}

export default class WebFooter extends React.Component<IWebFooterProps> {
  handleDisclaimerClick = () => {
    window.open('https://simplehai.axisdirect.in/disclaimer-home')
  }

  handleAppStore = () => {
    window.open('http://onelink.to/akvkqz')
  }

  render() {
    return (
      <DsBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <DsBox
          sx={{
            display: { md: 'flex', xs: 'none' },
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: 'var(--ds-spacing-bitterCold)',
            alignItems: 'center',
            border: '1px solid var(--ds-colour-strokeDefault)',
            borderRadius: 'var(--ds-spacing-glacial)',
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            mt: 'var(--ds-spacing-mild)'
          }}
        >
          <DsBox sx={{ display: 'flex', alignItems: 'center' }}>
            <DsTypography variant="bodyRegularSmall">
              Download App:
            </DsTypography>
            <DsBox
              sx={{
                ml: 'var(--ds-spacing-glacial)',
                display: 'flex',
                gap: 'var(--ds-spacing-bitterCold)',
                alignItems: 'center'
              }}
            >
              <DsLink underline="none" onClick={this.handleAppStore}>
                <DsImage
                  srcSet={APPLE_STORE_ASSEST}
                  alt=""
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                />
              </DsLink>
              <DsLink underline="none" onClick={this.handleAppStore}>
                <DsImage
                  srcSet={PLAY_STORE_ASSEST}
                  alt=""
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 'var(--ds-spacing-deepFreeze)'
                  }}
                />
              </DsLink>
            </DsBox>
          </DsBox>
          <DsBox
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <DsLink underline="none" onClick={this.handleDisclaimerClick}>
              Disclaimer
            </DsLink>
          </DsBox>
        </DsBox>
        <DsTypography
          textAlign={'center'}
          sx={{
            display: { md: 'block', xs: 'none' },
            mt: 'var(--ds-spacing-plasma)',
            mb: 'var(--ds-spacing-pleasant)',
            color: 'var(--ds-colour-typoTertiary)'
          }}
          variant="bodyRegularSmall"
        >
          Axis Securities Limited, <br /> &copy; 1999-{new Date().getFullYear()}
          , All rights reserved, built with ❤️ in India
        </DsTypography>
      </DsBox>
    )
  }
}
