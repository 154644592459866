import * as yup from 'yup'

const createPasswordValidationSchema = yup.object({
  newPassword: yup
    .string()
    .required()
    .matches(/^(?=.*[A-Za-z])(?=.*\d).{6,12}$/, {
      message: 'Password does not match policy'
    }),
  confirmNewPassword: yup
    .string()
    .required('')
    .oneOf(
      [yup.ref('newPassword')],
      'Please make sure that your passwords match'
    )
})

export default createPasswordValidationSchema
