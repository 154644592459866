import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  LOGIN_IDENTIFIER_EMAIL_MOBILE: {
    pathname: '/login/identifier/email-mobile'
  } as AppRouteItem,
  LOGIN_IDENTIFIER_USERNAME: {
    pathname: '/login/identifier/username'
  } as AppRouteItem,
  LOGIN_IDENTIFIER_QRCODE: {
    pathname: '/login/identifier/qrcode'
  } as AppRouteItem,
  SUBSEQUENT_LOGIN_CHALLENGE_PASSWORD: {
    pathname: '/subsequent-login/challenge/password'
  },
  LOGIN_CHALLENGE_PASSWORD: {
    pathname: '/login/challenge/password'
  } as AppRouteItem,
  LOGIN_SELECT_NRI_ACCOUNT: {
    pathname: '/login/NRI/accounts'
  } as AppRouteItem,
  LOGIN_CHALLENGE_ACCESS_CODE: {
    pathname: '/login/challenge/access-code'
  } as AppRouteItem,
  FORGOT_USERNAME_IDENTIFIER_PAN_EMAIL_MOBILE: {
    pathname: '/forgot-username/identifier/pan-email-mobile'
  } as AppRouteItem,
  FORGOT_USERNAME_CHALLENGE_OTP: {
    pathname: '/forgot-username/challenge/otp'
  } as AppRouteItem,
  FORGOT_USERNAME_SET_USERNAME: {
    pathname: '/forgot-username/set/username'
  } as AppRouteItem,
  FORGOT_PASSWORD_IDENTIFIER_PAN_EMAIL_MOBILE: {
    pathname: '/forgot-password/identifier/pan-email-mobile'
  } as AppRouteItem,
  FORGOT_PASSWORD_CHALLENGE_OTP: {
    pathname: '/forgot-password/challenge/otp'
  } as AppRouteItem,
  FORGOT_PASSWORD_SET_PASSWORD: {
    pathname: '/forgot-password/set/password'
  } as AppRouteItem,
  CHANGE_PASSWORD_SET_PASSWORD: {
    pathname: '/change-password/set/password'
  } as AppRouteItem,
  CHANGE_PASSWORD_CHALLENGE_PASSWORD: {
    pathname: '/change-password/challenge/password'
  } as AppRouteItem,
  UNLOCK_ACCOUNT_ACTION_SELECTION: {
    pathname: '/unlock-account/action/selection'
  } as AppRouteItem,
  UNLOCK_ACCOUNT_IDENTIFIER_PAN_EMAIL_MOBILE: {
    pathname: '/unlock-account/identifier/pan-email-mobile'
  } as AppRouteItem,
  UNLOCK_ACCOUNT_CHALLENGE_OTP: {
    pathname: '/unlock-account/challenge/otp'
  } as AppRouteItem,
  UNLOCK_ACCOUNT_SET_PASSWORD: {
    pathname: '/unlock-account/set/password'
  } as AppRouteItem,
  CREATE_USERNAME_IDENTIFIER_PAN_EMAIL_MOBILE: {
    pathname: '/create-username/identifier/pan-email-mobile'
  } as AppRouteItem,
  CREATE_USERNAME_CHALLENGE_OTP: {
    pathname: '/create-username/challenge/otp'
  } as AppRouteItem,
  CREATE_USERNAME_SET_USERNAME: {
    pathname: '/create-username/set/username'
  } as AppRouteItem,
  VALIDATE_SSO: {
    pathname: '/sso/validate'
  } as AppRouteItem,
  PASSWORD_EXPIRED_RESET_PASSWORD: {
    pathname: '/password-expired/reset-password'
  } as AppRouteItem,
  SOMETHING_WENT_WRONG: {
    pathname: '/something-went-wrong'
  } as AppRouteItem,
  SESSION_EXPIRED: {
    pathname: '/session-expired'
  } as AppRouteItem,
  REGISTER_LINK: {
    pathname: 'https://digitalaccount.axisdirect.in/register'
  },
  OLD_USERNAME_FLOW: {
    pathname:
      'https://simplehai.axisdirect.in/app/index.php/user/auth/activateUser'
  },
  TERMS_CONDITIONS: {
    pathname: 'https://simplehai.axisdirect.in/terms-and-conditions-home'
  },
  ANY: {
    pathname: '*'
  } as AppRouteItem,
  HOME: {
    pathname: '/'
  } as AppRouteItem
}

export default APP_ROUTES
