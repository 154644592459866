import * as React from 'react'
import { DsBox, DsTypography } from '@am92/react-design-system'

export interface IContainerlabel {
  label: string
  subLabel: string
}

export default class ContainerLabel extends React.Component<IContainerlabel> {
  render() {
    const { label = '', subLabel = '' } = this.props

    return (
      <DsBox>
        <DsTypography component={'div'} variant="headingBoldLarge" gutterBottom>
          {label}
        </DsTypography>

        <DsTypography
          component={'div'}
          variant="bodyRegularMedium"
          color="text.secondary">
          {subLabel}
        </DsTypography>
      </DsBox>
    )
  }
}
