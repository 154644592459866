import React, { Component } from 'react'
import { DsBackdrop, DsCircularProgress } from '@am92/react-design-system'

class Loader extends Component {
  render() {
    return (
      <DsBackdrop open invisible>
        <DsCircularProgress />
      </DsBackdrop>
    )
  }
}

export default Loader
