import * as React from 'react'
import { DsBox, DsImage, DsRemixIcon } from '@am92/react-design-system'

// custom components
import withRouter, { WithRouter } from '~/src/Lib/withRouter'

// constants
import { HEADER_MOBILE_ASSEST } from '../Constants/ASSEST_MAP'

export interface IPageHeaderProps extends WithRouter {
  showBackButton?: boolean
  showAxisLogo?: boolean
  optionalNavigation?: string
  callFuncOnBackBtnPress?: () => void
}
class PageHeader extends React.Component<IPageHeaderProps> {
  navigateTo = () => {
    const { navigateTo } = this.props
    navigateTo(-1)
  }

  navigateOptional = (route: string) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleBack() {
    const { callFuncOnBackBtnPress, optionalNavigation } = this.props
    if (callFuncOnBackBtnPress) {
      callFuncOnBackBtnPress()
    } else {
      optionalNavigation
        ? this.navigateOptional(optionalNavigation)
        : this.navigateTo()
    }
  }

  render() {
    const { showBackButton = true } = this.props
    const { showAxisLogo = true } = this.props
    return (
      <>
        <DsBox
          sx={{
            display: 'flex',
            visibility: showBackButton ? 'visible' : 'hidden',
            position: { md: 'absolute', xs: 'unset' },
            top: { md: '-36px', xs: '0' },
            left: { md: '-5px', xs: '0' },
            mb: {
              md: 'var(--ds-spacing-zero)',
              xs: showAxisLogo
                ? 'var(--ds-spacing-bitterCold)'
                : 'var(--ds-spacing-molten)'
            },
            cursor: 'pointer',
            alignItems: 'center'
          }}
          onClick={() => this.handleBack()}
        >
          <DsRemixIcon className="ri-arrow-left-line" />
        </DsBox>
        <DsBox
          sx={{
            display: { md: 'none', xs: showAxisLogo ? 'flex' : 'none' },
            justifyContent: 'center',
            mt: 'var(--ds-spacing-frostbite)',
            mb: 'var(--ds-spacing-warm)'
          }}
        >
          <DsImage srcSet={HEADER_MOBILE_ASSEST} alt="Axis Logo" />
        </DsBox>
      </>
    )
  }
}

export default withRouter(PageHeader)
