import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const resetDataAction = createAction<string[], string>(
  'journey/resetData'
)

export const updateEmailOrMobileAction = createAction<string, string>(
  'journey/updateEmailOrMobile'
)

export const updateUsernameAction = createAction<string, string>(
  'journey/updateusername'
)

export const updateIdentifierTypeAction = createAction<string, string>(
  'journey/updateIdentifierType'
)

export const updateCurrentPasswordAction = createAction<string, string>(
  'journey/updateCurrentPassword'
)

export const updateResetPasswordOptionAction = createAction<string, string>(
  'journey/updateResetPasswordOption'
)

export const updateCustomerNameAction = createAction<string, string>(
  'journey/updateCustomerName'
)

export const switchAccountAction = createAction<void, string>(
  'journey/switchAccount'
)

export const clearUserDataAction = createAction<void, string>(
  'journey/clearUserData'
)

export const updateIsTermsAndConditionsAcceptedAction = createAction<
  boolean,
  string
>('journey/updateIsTermsAndConditionsAccepted')

export type UpdatePasswordsActionProps = {
  newPassword: string
  confirmNewPassword: string
}

export const updatePasswordsAction = createAction<
  UpdatePasswordsActionProps,
  string
>('journey/updatePasswords')

export const updatePanAction = createAction<string, string>('journey/updatePan')

export const updateNewUsernameAction = createAction<string, string>(
  'journey/updateNewUsername'
)

//SSO
export const validateSSOServiceName = 'journey/validateSSO'
export const validateSSOActions = traceActionsCreator(validateSSOServiceName)

export const completeSSOServiceName = 'journey/completeSSO'
export const completeSSOActions = traceActionsCreator(completeSSOServiceName)

export const initiateSSOServiceName = 'journey/initiateSSO'
export const initiateSSOActions = traceActionsCreator(initiateSSOServiceName)

//login
export const canLoginServiceName = 'journey/canLogin'
export const canLoginActions = traceActionsCreator(canLoginServiceName)

export const generateAccessCodeServiceName = 'journey/generateAccessCode'
export const generateAccessCodeActions = traceActionsCreator(
  generateAccessCodeServiceName
)

export const checkLoginStatusByTokenServiceName = 'journey/checkQrLoginStatus'
export const checkLoginStatusByTokenActions = traceActionsCreator(
  checkLoginStatusByTokenServiceName
)

export const generateQrcodeTokenServiceName = 'journey/generateQrcodeToken'
export const generateQrcodeTokenActions = traceActionsCreator(
  generateQrcodeTokenServiceName
)

export const validateAccessCodeServiceName = 'journey/validateAccessCode'
export const validateAccessCodeActions = traceActionsCreator(
  validateAccessCodeServiceName
)

export const resendAccessCodeServiceName = 'journey/resendAccessCode'
export const resendAccessCodeActions = traceActionsCreator(
  resendAccessCodeServiceName
)

//Forgot Password - create password
export const forgotPasswordPanMobileServiceName =
  'journey/forgotPasswordPanMobile'
export const forgotPasswordPanMobileActions = traceActionsCreator(
  forgotPasswordPanMobileServiceName
)

export const forgotPasswordValidateOtpServiceName =
  'journey/forgotPasswordValidateOtp'
export const forgotPasswordValidateOtpActions = traceActionsCreator(
  forgotPasswordValidateOtpServiceName
)

export const forgotPasswordConfirmPasswordServiceName =
  'journey/forgotPasswordConfirmPassword'
export const forgotPasswordConfirmPasswordActions = traceActionsCreator(
  forgotPasswordConfirmPasswordServiceName
)

//Unlock Account
export const unlockAccountPanMobileServiceName =
  'journey/unlockAccountPanMobile'
export const unlockAccountPanMobileActions = traceActionsCreator(
  unlockAccountPanMobileServiceName
)

export const unlockAccountValidateOtpServiceName =
  'journey/unlockAccountValidateOtp'
export const unlockAccountValidateOtpActions = traceActionsCreator(
  unlockAccountValidateOtpServiceName
)

export const unlockAccountConfirmPasswordServiceName =
  'journey/unlockAccountConfirmPassword'
export const unlockAccountConfirmPasswordActions = traceActionsCreator(
  unlockAccountConfirmPasswordServiceName
)

//forgot username
export const forgotUsernamePanMobileServiceName =
  'journey/forgotUsernamePanMobile'
export const forgotUsernamePanMobileActions = traceActionsCreator(
  forgotUsernamePanMobileServiceName
)

export const forgotUsernameValidateOtpServiceName =
  'journey/forgotUsernameValidateOtp'
export const forgotUsernameValidateOtpActions = traceActionsCreator(
  forgotUsernameValidateOtpServiceName
)

export const forgotUsernameConfirmUsernameServiceName =
  'journey/forgotUsernameConfirmUsername'
export const forgotUsernameConfirmUsernameActions = traceActionsCreator(
  forgotUsernameConfirmUsernameServiceName
)

export const forgotUsernameResendUsernameServiceName =
  'journey/forgotUsernameResendUsername'
export const forgotUsernameResendUsernameActions = traceActionsCreator(
  forgotUsernameResendUsernameServiceName
)

//passwordExpiredResetPassword
export const passwordExpiredResetPasswordServiceName =
  'journey/passwordExpiredResetPassword'
export const passwordExpiredResetPasswordActions = traceActionsCreator(
  passwordExpiredResetPasswordServiceName
)

//create username
export const createUsernamePanMobileServiceName =
  'journey/createUsernamePanMobile'
export const createUsernamePanMobileActions = traceActionsCreator(
  createUsernamePanMobileServiceName
)

export const createUsernameValidateOtpServiceName =
  'journey/createUsernameValidateOtp'
export const createUsernameValidateOtpActions = traceActionsCreator(
  createUsernameValidateOtpServiceName
)

export const createUsernameConfirmUsernameServiceName =
  'journey/createUsernameConfirmUsername'
export const createUsernameConfirmUsernameActions = traceActionsCreator(
  createUsernameConfirmUsernameServiceName
)

// Post Login
export const postLoginCheckServiceName = 'journey/postLoginCheck'
export const postLoginCheckActions = traceActionsCreator(
  postLoginCheckServiceName
)

// Change Password
export const changePasswordVerifyPasswordServiceName =
  'journey/changePasswordVerifyPassword'
export const changePasswordVerifyPasswordActions = traceActionsCreator(
  changePasswordVerifyPasswordServiceName
)

export const changePasswordConfirmPasswordServiceName =
  'journey/changePasswordConfirmPassword'
export const changePasswordConfirmPasswordActions = traceActionsCreator(
  changePasswordConfirmPasswordServiceName
)

// Customer support
export const customerSupportServiceName = 'journey/customerSupport'
export const customerSupportActions = traceActionsCreator(
  customerSupportServiceName
)

export const updateSelectedAccountAction = createAction<void, string>(
  'journey/updateSelectedAccount'
)
