import { WebHttpRequestOptions } from '@am92/web-http'
import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import {
  passwordExpiredResetPasswordServiceName,
  passwordExpiredResetPasswordActions
} from '../../Actions'

export type passwordExpiredResetPasswordData = {
  newPassword: string
  confirmNewPassword: string
  subAccountId: string
}

async function passwordExpiredResetPassword(
  reqData: passwordExpiredResetPasswordData
) {
  const { newPassword, confirmNewPassword, subAccountId } = reqData

  const options: WebHttpRequestOptions = {
    url: '/customers/reset-password',
    method: 'POST',
    data: {
      newPassword,
      confirmNewPassword
    },
    headers: {
      'X-SubAccountID': subAccountId
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  return body
}

const passwordExpiredResetPasswordService =
  serviceActionCreator<passwordExpiredResetPasswordData>(
    passwordExpiredResetPasswordActions,
    passwordExpiredResetPassword
  )

export default passwordExpiredResetPasswordService
export { passwordExpiredResetPasswordServiceName }
