import * as React from 'react'
import { DsBox, DsImage } from '@am92/react-design-system'

// constants
import { LIGHT_MONEY_GROW_ASSEST, NRI } from '~/src/Constants/ASSEST_MAP'

export interface IWebLeftContentProps {}

export default class WebLeftContent extends React.Component<IWebLeftContentProps> {
  render() {
    const url = window.location.href
    const pathname = new URL(url).pathname

    const formattedImage = pathname.includes('NRI')
      ? NRI
      : LIGHT_MONEY_GROW_ASSEST

    return (
      <DsBox
        sx={{
          position: 'relative',
          display: { lg: 'flex', md: 'flex', xs: 'none' },
          width: '40%',
          backgroundColor: '#FFF2F8'
        }}
      >
        <DsImage
          srcSet={formattedImage}
          style={{
            width: '100%',
            display: 'flex'
          }}
        />
      </DsBox>
    )
  }
}
