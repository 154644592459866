import { jsx as _jsx } from "react/jsx-runtime";
import React, { PureComponent } from 'react';
import { DsStack } from '../DsStack';
import { DsButtonGroupDefaultProps } from './DsButtonGroup.Types';
export class DsButtonGroup extends PureComponent {
    static defaultProps = DsButtonGroupDefaultProps;
    render() {
        const { fullWidth, noPadding, size, sx, children, ...restProps } = this.props;
        const childrenArray = children instanceof Array ? children : [children];
        return (_jsx(DsStack, { direction: "row", spacing: "var(--ds-spacing-frostbite)", sx: {
                bgcolor: 'var(--ds-colour-surfacePrimary)',
                p: noPadding
                    ? 'var(--ds-spacing-zero)'
                    : 'var(--ds-spacing-bitterCold)',
                ...sx
            }, ...restProps, children: childrenArray.map((child, key) => child && React.cloneElement(child, { key, size, fullWidth })) }));
    }
}
